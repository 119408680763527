<template>
  <PageWrapper>
    <PagePanel>
      <PageSearchArea @queryData="queryRebateReport">
        <template #form>
          <el-form label-position="top">
            <AccountNumber @accountCallback="init"></AccountNumber>
            <DateRangePicker v-bind:startDate.sync="startDate" v-bind:endDate.sync="endDate"></DateRangePicker>
            <el-form-item label="产品类型">
              <el-cascader
                expand-trigger="hover"
                :options="instrumentOptions"
                v-model="selectedInstrument"
                :show-all-levels="false"
              ></el-cascader>
            </el-form-item>
          </el-form>
        </template>
      </PageSearchArea>
    </PagePanel>

    <div class="main">
      <!-- 5个盒子 -->
      <div class="main-top">
        <div class="calendar-box">
          <ul class="clearfix">
            <li class="special">
              <div class="top">
                <p>{{ $t('report.rebate.others.rebate') }}</p>
              </div>
              <div class="bottom clearfix row">
                <div class="bottom_left">
                  <p>
                    <span>{{ currAccountCurrencyFilter }}</span>
                    {{ totalRebate | currency('') }}
                  </p>
                  <span>{{ $t('report.rebate.others.totalRebate') }}</span>
                </div>
              </div>
            </li>

            <li v-for="(summary, index) in summaries" v-bind:key="index">
              <div class="top">
                <p>{{ summary.category }}</p>
              </div>
              <div class="bottom clearfix">
                <div class="bottom_left">
                  <p>{{ summary.volume | formatNumber }}</p>
                  <span>{{ $t('report.rebate.others.lots') }}</span>
                </div>
                <div class="bottom_right">
                  <p>
                    <span>{{ currAccountCurrencyFilter }}</span>
                    {{ summary.commission | currency('') }}
                  </p>
                  <span>{{ $t('report.rebate.others.rebate') }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 表格部分 -->
      <div class="main-bottom">
        <div class="content clearfix">
          <div class="content-box">
            <span>
              {{ $t('report.rebate.others.rebate') }} {{ $t('common.keys.FROM') }}

              <span class="blue">
                {{ startDate | formatDate('DD/MM/YYYY') }} - {{ endDate | formatDate('DD/MM/YYYY') }} >>></span
              >
            </span>
            <div class="table-box">
              <el-table
                :data="displayData"
                ref="summaryTable"
                style="width: 100%;"
                :default-sort="{ prop: 'totalrebate', order: 'descending' }"
                @sort-change="sortChange"
              >
                <el-table-column :label="$t('report.rebate.column.name')" style="word-wrap: normal;" min-width="100">
                  <template slot-scope="scope">
                    <a
                      @click.stop.prevent="
                        dialogVisible = true;
                        viewTradeDetail = { name: scope.row.name, mt4Account: scope.row.number };
                      "
                      data-testid="name"
                      >{{ scope.row.name }}</a
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="number"
                  :label="$t('report.rebate.column.accountNumber')"
                  min-width="120"
                ></el-table-column>
                <el-table-column :label="$t('report.rebate.column.accountType')" min-width="70">
                  <template slot-scope="scope">
                    {{ $config.accountTypeMaps[scope.row.type] }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('common.products.forex')"
                  sortable
                  sort-by="forex.rebate"
                  :sort-orders="['ascending', 'descending']"
                >
                  <el-table-column :label="$t('report.rebate.column.volume')">
                    <template slot-scope="scope">
                      <span>{{ scope.row.forex.volume | formatNumber }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('report.rebate.column.rebate')">
                    <template slot-scope="scope">
                      <span>{{ currAccountCurrencyFilter }}{{ scope.row.forex.rebate | currency('') }}</span>
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column
                  :label="$t('common.products.commodities')"
                  sortable
                  sort-by="commodities.rebate"
                  :sort-orders="['ascending', 'descending']"
                >
                  <el-table-column :label="$t('report.rebate.column.volume')">
                    <template slot-scope="scope">
                      <span>{{ scope.row.commodities.volume | formatNumber }}</span>
                      <p style="border-right: 1px solid red;"></p>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('report.rebate.column.rebate')">
                    <template slot-scope="scope">
                      <span>{{ currAccountCurrencyFilter }}{{ scope.row.commodities.rebate | currency('') }}</span>
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column
                  :label="$t('common.products.indices')"
                  sortable
                  sort-by="indices.rebate"
                  :sort-orders="['ascending', 'descending']"
                >
                  <el-table-column :label="$t('report.rebate.column.volume')">
                    <template slot-scope="scope">
                      <span>{{ scope.row.indices.volume | formatNumber }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('report.rebate.column.rebate')">
                    <template slot-scope="scope">
                      <span>{{ currAccountCurrencyFilter }}{{ scope.row.indices.rebate | currency('') }}</span>
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column
                  :label="$t('common.products.crypto')"
                  sortable
                  sort-by="crypto.rebate"
                  :sort-orders="['ascending', 'descending']"
                >
                  <el-table-column :label="$t('report.rebate.column.volume')">
                    <template slot-scope="scope">
                      <span>{{ scope.row.crypto.volume | formatNumber }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('report.rebate.column.rebate')">
                    <template slot-scope="scope">
                      <span>{{ currAccountCurrencyFilter }}{{ scope.row.crypto.rebate | currency('') }}</span>
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column
                  :label="$t('common.products.sharecfd')"
                  sortable
                  sort-by="sharecfd.rebate"
                  :sort-orders="['ascending', 'descending']"
                >
                  <el-table-column :label="$t('report.rebate.column.volume')" min-width="100">
                    <template slot-scope="scope">
                      <span>{{ scope.row.sharecfd.volume | formatNumber }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('report.rebate.column.rebate')" min-width="70">
                    <template slot-scope="scope">
                      <span>{{ currAccountCurrencyFilter }}{{ scope.row.sharecfd.rebate | currency('') }}</span>
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column
                  :label="$t('common.products.bond')"
                  sortable
                  sort-by="bond.rebate"
                  :sort-orders="['ascending', 'descending']"
                >
                  <el-table-column :label="$t('report.rebate.column.volume')" min-width="100">
                    <template slot-scope="scope">
                      <span>{{ scope.row.bond.volume | formatNumber }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('report.rebate.column.rebate')" min-width="70">
                    <template slot-scope="scope">
                      <span>{{ currAccountCurrencyFilter }}{{ scope.row.bond.rebate | currency('') }}</span>
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column
                  :label="$t('report.rebate.column.totalRebate')"
                  sortable
                  sort-by="totalrebate"
                  :sort-orders="['ascending', 'descending']"
                  min-width="110"
                >
                  <template slot-scope="scope">
                    <span>{{ currAccountCurrencyFilter }}{{ scope.row.totalrebate | currency('') }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <pagination v-bind:table-data="tableData" v-bind:display-data.sync="displayData"></pagination>
          </div>
        </div>
      </div>
    </div>
    <Dialog :dialogVisible.sync="dialogVisible" :viewTradeDetail="viewTradeDetail"></Dialog>
  </PageWrapper>
</template>

<script>
import Pagination from '@/components/Pagination';
import LoadingButton from '@/components/LoadingButton';
import DateRangePicker from '@/components/customUI/DateRangePicker';
import Dialog from '@/components/rebateReport/Dialog';
import AccountNumber from '@/components/customUI/AccountNumber';
import FakePagination from '@/components/elementUI/Pagination/FakePagination';
import { apiRebate_report, apiGet_instruments } from '@/resource';

export default {
  components: { Pagination, LoadingButton, DateRangePicker, Dialog, AccountNumber, FakePagination },
  data() {
    return {
      startDate: '',
      endDate: '',
      userID: this.$store.state.common.CUID,
      dialogVisible: false,
      totalRebate: 0,
      summaries: [
        {
          category: this.$t('common.products.forex'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.commodities'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.indices'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.crypto'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.sharecfd'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.bond'),
          commission: 0,
          volume: 0
        }
      ],
      viewTradeDetail: { name: null, mt4Account: null },
      selectedInstrument: [null],
      instrumentOptions: [
        {
          value: null,
          label: this.$t('report.rebate.dropdown.allInstruments')
        },
        {
          value: 'forex',
          label: this.$t('common.products.forex'),
          children: []
        },
        {
          value: 'commodities',
          label: this.$t('common.products.commodities'),
          children: []
        },
        {
          value: 'indices',
          label: this.$t('common.products.indices'),
          children: []
        },
        {
          value: 'crypto',
          label: this.$t('common.products.crypto'),
          children: []
        },
        {
          value: 'sharecfd',
          label: this.$t('common.products.sharecfd'),
          children: []
        },
        {
          value: 'bond',
          label: this.$t('common.products.bond'),
          children: []
        }
      ],
      // 分页
      displayData: [],
      sorting: { column: 'totalrebate', order: 'descending' },
      tableData: [],
      currAccountCurrencyFilter: ''
    };
  },
  watch: {
    dataSourceId() {
      this.queryInstruments();
    }
  },
  methods: {
    queryRebateReport() {
      if (this.accountID === null || this.accountID.length < 1) {
        return Promise.resolve();
      }

      return apiRebate_report({
        account: this.accountID,
        startDate: this.startDate,
        endDate: this.endDate,
        symbol:
          Array.isArray(this.selectedInstrument) && this.selectedInstrument.length == 2
            ? this.selectedInstrument[1]
            : null
      }).then(response => {
        this.totalRebate = response.data.total;

        let keyMap = { 'share cfd': 'sharecfd' };
        this.tableData = response.data.payerSummaries.map(item => {
          var objs = Object.keys(item).reduce((newData, key) => {
            let newKey = keyMap[key] || key;
            newData[newKey] = item[key];
            return newData;
          }, {});
          return objs;
        });

        this.currAccountCurrencyFilter = this.$options.filters.currencySymbol(this.currAccountCurrency);

        this.sortData();
        this.summaries.forEach(s => {
          s.commission = s.volume = 0;
          for (let key in response.data.summaries) {
            let temp = key.toLowerCase().replace(/\s+/g, '');
            temp = this.$t('common.products.' + temp);
            if (temp === s.category) {
              s.commission = response.data.summaries[key].commission;
              s.volume = response.data.summaries[key].volume;
              break;
            }
          }
        });
      }, this.$handleError);
    },
    sortData() {
      this.sorting.column = this.sorting.column || 'totalrebate';
      this.sorting.order = this.sorting.order || 'descending';
      this.tableData.sort((a1, a2) => {
        if (this.sorting.column == 'totalrebate') {
          return this.sorting.order == 'descending'
            ? a2['totalrebate'] - a1['totalrebate']
            : a1['totalrebate'] - a2['totalrebate'];
        }
        if (this.sorting.column == 'forex.rebate') {
          return this.sorting.order == 'descending'
            ? a2['forex']['rebate'] - a1['forex']['rebate']
            : a1['forex']['rebate'] - a2['forex']['rebate'];
        }
        if (this.sorting.column == 'commodities.rebate') {
          return this.sorting.order == 'descending'
            ? a2['commodities']['rebate'] - a1['commodities']['rebate']
            : a1['commodities']['rebate'] - a2['commodities']['rebate'];
        }
        if (this.sorting.column == 'indicies.rebate') {
          return this.sorting.order == 'descending'
            ? a2['indicies']['rebate'] - a1['indicies']['rebate']
            : a1['indicies']['rebate'] - a2['indicies']['rebate'];
        }
        if (this.sorting.column == 'crypto.rebate') {
          return this.sorting.order == 'descending'
            ? a2['crypto']['rebate'] - a1['crypto']['rebate']
            : a1['crypto']['rebate'] - a2['crypto']['rebate'];
        }
        if (this.sorting.column == 'sharecfd.rebate') {
          return this.sorting.order == 'descending'
            ? a2['sharecfd']['rebate'] - a1['sharecfd']['rebate']
            : a1['sharecfd']['rebate'] - a2['sharecfd']['rebate'];
        }
        if (this.sorting.column == 'bond.rebate') {
          return this.sorting.order == 'descending'
            ? a2['bond']['rebate'] - a1['bond']['rebate']
            : a1['bond']['rebate'] - a2['bond']['rebate'];
        }
      });
    },
    queryInstruments() {
      if (!this.dataSourceId) {
        return;
      }

      //恢復預設
      this.instrumentOptions.forEach(i => {
        if (Array.isArray(i.children)) i.children = [];
      });

      apiGet_instruments({ dataSourceId: this.dataSourceId }).then(response => {
        for (let k in response.data) {
          let instrument = this.instrumentOptions.find(i => i.value == k.replace(/\s+/g, ''));
          if (instrument && Array.isArray(instrument.children)) {
            instrument.children = response.data[k].sort().map(prod => {
              return { value: prod, label: prod };
            });
          }
        }
      }, this.$handleError);
    },
    sortChange(column) {
      if (!column.column) {
        return;
      }
      this.sorting = { column: column.column.sortBy, order: column.order };
      this.sortData();
      console.log(this.displayData);
    },
    init() {
      this.queryInstruments();
      this.queryRebateReport();
    }
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    currAccountCurrency() {
      return this.$store.state.behavior.CUR;
    },
    dataSourceId() {
      return this.$store.state.behavior.dataSourceId;
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/rebateReport.scss';
</style>
